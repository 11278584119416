import { Spinner } from 'shared/Spinner';
import styled from 'styled-components';
import { useNotifications, useReadNotifications } from './notificationsQuery';
import Typography, { TypeVariant } from 'shared/Typography';
import { groupNotifications } from './notificationsHelpers';
import { NotificationItem } from './NotificationItem';
import UnstyledButton from 'shared/UnstyledButton';
import Icon, { IconNames } from 'shared/Icons';

const emptyText =
  'Welcome to your notifications center! Here you will receive updates from your communities and creators.';

const NotificationsList = () => {
  const { data } = useNotifications();

  const readNotificationsMutation = useReadNotifications();

  if (data) {
    const unreadNotifications = data.filter(
      (notification) => !notification.read,
    );
    const groupedNotifications = groupNotifications(unreadNotifications);

    const readNotifications = data
      .filter((notification) => notification.read)
      .sort((a, b) => {
        return (
          -1 *
          (new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime())
        );
      });

    const handleReadAll = (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      const allUnreadNotificationIds = groupedNotifications
        .map((group) => group.map((notification) => notification.id))
        .flat();

      readNotificationsMutation.mutate(allUnreadNotificationIds);
    };

    return (
      <section>
        <TitleWrapper>
          <Title>Notifications</Title>
          <Form onSubmit={handleReadAll}>
            <MarkAsReadButton
              type="submit"
              disabled={!!!groupedNotifications.length}
            >
              <Subtitle>Mark All as Read</Subtitle>
              <IconWrapper>
                <Icon
                  id={IconNames.check}
                  width={16}
                  height={16}
                  color="var(--icon-color)"
                />
              </IconWrapper>
            </MarkAsReadButton>
          </Form>
        </TitleWrapper>
        {!!data.length ? (
          <NotificationsWrapper>
            {groupedNotifications.map((group, index) => (
              <NotificationItem key={index} notifications={group} />
            ))}
            {readNotifications.map((notification, index) => (
              <NotificationItem
                key={`read-${index}`}
                notifications={[notification]}
              />
            ))}
          </NotificationsWrapper>
        ) : (
          <Empty>{emptyText}</Empty>
        )}
      </section>
    );
  }

  return (
    <section>
      <TitleWrapper>
        <Title>Notifications</Title>
      </TitleWrapper>
      <SpinnerWrap>
        <Spinner size="medium" />
      </SpinnerWrap>
    </section>
  );
};

export { NotificationsList };

const NotificationsWrapper = styled.div`
  overflow-y: auto;
  scrollbar-color: var(--color-gray-700) var(--color-gray-900);
  max-height: calc(100vh - 164px);

  &::-webkit-scrollbar {
    display: block;
    background-color: var(--color-gray-900);
    width: 10px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 999px;
    background-color: var(--color-gray-500);
    border: 1.5px solid var(--color-gray-900);
  }

  @media ${(p) => p.theme.queries.tabletAndDown} {
    overflow-y: revert;
    max-height: revert;
  }
`;

const Title = ({ children }: { children: React.ReactNode }) => (
  <Typography
    variant={TypeVariant.bodyLarge01Bold}
    tablet={TypeVariant.body02Bold}
    as="h2"
    color={{
      default: 'var(--color-white)',
      tablet: 'var(--color-black-500)',
    }}
  >
    {children}
  </Typography>
);

const Subtitle = ({ children }: { children: React.ReactNode }) => (
  <Typography
    variant={TypeVariant.body02}
    as="p"
    color={{
      default: 'var(--color-white)',
      tablet: 'var(--color-black-500)',
    }}
  >
    {children}
  </Typography>
);

const EmptyWrap = styled.div`
  padding: 16px 20px;

  @media ${(p) => p.theme.queries.tabletAndDown} {
    padding-top: 8px;
  }
`;

const Empty = ({ children }: { children: React.ReactNode }) => (
  <EmptyWrap>
    <Typography
      variant={TypeVariant.body02}
      as="p"
      color={{
        default: 'var(--color-white)',
        tablet: 'var(--color-black-500)',
      }}
    >
      {children}
    </Typography>
  </EmptyWrap>
);

const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px 20px 8px 20px;

  @media ${(p) => p.theme.queries.tabletAndDown} {
    padding: 20px 20px 8px 20px;
  }
`;

const Form = styled.form``;

const IconWrapper = styled.div`
  /* used to make icon align with text*/
  padding-bottom: 2px;
`;

const MarkAsReadButton = styled.button`
  cursor: pointer;
  border: none;
  outline: none;
  background: none;
  display: flex;
  gap: 4px;
  align-items: center;

  --icon-color: var(--color-white);
  @media ${(p) => p.theme.queries.tabletAndDown} {
    --icon-color: var(--color-black-500);
  }
`;

const SpinnerWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
