import Link from 'next/link';
import Icon, { IconNames } from '../../Icons';
import styled from 'styled-components';
import { MediaContextProvider, Media } from 'media';
import { useRouter } from 'next/router';
import { NotificationsDialog } from 'components/Notifications/NotificationsDialog';
import { Notification } from '@solin-fitness/types';
import { groupNotifications } from 'components/Notifications/notificationsHelpers';
import { NotificationsList } from 'components/Notifications/NotificationsList';

type Props = {
  notifications: Notification[];
};

const NotificationsIcon = ({ notifications }: Props) => {
  const unreadNotifications = notifications.filter(
    (notification) => !notification.read,
  );
  const groupedNotifications = groupNotifications(unreadNotifications);

  const unreadCount = groupedNotifications.length;

  return (
    <MediaContextProvider>
      <Media greaterThanOrEqual="lg">
        <NotificationsDialog content={<NotificationsList />}>
          <Icons unreadCount={unreadCount} />
        </NotificationsDialog>
      </Media>
      <Media lessThan="lg">
        <Link
          href={{
            pathname: '/notifications',
          }}
          passHref
        >
          <a>
            <Icons unreadCount={unreadCount} />
          </a>
        </Link>
      </Media>
    </MediaContextProvider>
  );
};

const Icons = ({ unreadCount }: { unreadCount: number }) => {
  const router = useRouter();
  const isActive = router.pathname === '/notifications';
  return unreadCount > 0 ? (
    <UnreadWrap>
      <Icon
        id={IconNames.lightningWithSpark}
        width={33}
        height={33}
        color="var(--color-secondary-100)"
      />
      <Bubble>
        <NotificationCount>{unreadCount}</NotificationCount>
      </Bubble>
      {isActive ? <Underline unread /> : null}
    </UnreadWrap>
  ) : (
    <ReadWrap>
      <Icon
        id={IconNames.lightning}
        width="var(--size)"
        height="var(--size)"
        color="var(--color-white)"
      />
      {isActive ? <Underline /> : null}
    </ReadWrap>
  );
};

export { NotificationsIcon };

const UnreadWrap = styled.div`
  position: relative;
  width: 43px;
  padding-top: 2px;
  cursor: pointer;
`;

const Bubble = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 999px;
  background: var(--color-secondary-300);
`;

const NotificationCount = styled.p`
  font-family: 'Epilogue';
  font-size: var(--font-size-body-02);
  font-weight: var(--font-weight-semibold);
  color: var(--color-white);
  margin-bottom: -2px;
`;

const ReadWrap = styled.div`
  cursor: pointer;
  position: relative;

  --size: 29px;

  @media ${(p) => p.theme.queries.tabletAndDown} {
    --size: 24px;
  }
`;

const Underline = styled.div<{ unread?: boolean }>`
  position: absolute;
  bottom: ${(p) => (p.unread ? '0px' : '-5px')};
  left: 0;
  right: 0;
  border-bottom: 2px solid
    ${(p) => (p.unread ? 'var(--color-secondary-300)' : 'var(--color-white)')};
`;
