import { api, Methods } from 'services/api';
import { Notification, NotificationWithPost } from '@solin-fitness/types';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import useAuthStore from 'hooks/useAuthStore';

export const notificationKeys = {
  all: ['notifications'] as const,
  byId: (id: string | number) => [...notificationKeys.all, Number(id)] as const,
};

const getNotifications = async () =>
  api<Notification[]>(Methods.get, '/notifications');

const useNotifications = () => {
  const { isSignedIn } = useAuthStore();

  return useQuery(notificationKeys.all, getNotifications, {
    enabled: isSignedIn,
  });
};

const readNotifications = async (notifications: number[]) =>
  api(Methods.post, '/notifications', {
    notifications,
  });

const useReadNotifications = () => {
  const queryClient = useQueryClient();

  return useMutation(readNotifications, {
    onSuccess: () => {
      queryClient.invalidateQueries(notificationKeys.all);
    },
  });
};

const getNotificationById = async (
  id: string | number,
  headers?: Record<string, string | undefined>,
) =>
  api<NotificationWithPost>(Methods.get, `/notifications/${id}`, {
    headers,
  });

const useNotificationsById = (id: string | number) =>
  useQuery(notificationKeys.byId(id), () => getNotificationById(id));

export {
  useNotifications,
  useReadNotifications,
  useNotificationsById,
  getNotificationById,
};
